import React from "react";
import { useSelector } from "react-redux";
import { State, showDetails } from "./store";
import sanitizeHtml from "sanitize-html";
import Modal from 'react-modal';
import Carousel from "./Carousel";
import ModalCloseButton from "./ModalCloseButton";
import preventScroll from "./preventScroll";

function DetailsModal() {
  const close = () => showDetails(undefined)
  const product = useSelector((state: State) => state.detailsModal);

  preventScroll(!!product);

  if (!product) {
    return null;
  }

  const variant = product.variants[product.selectedVariant];

  const description = sanitizeHtml(variant.description || "", {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
  });

  return (
    <Modal
      isOpen
      onRequestClose={close}
      className="details-modal"
    >
      <div className="details-modal__header">
        <div
          className="details-modal__close-button"
          onClick={close}
        >
          <ModalCloseButton />
        </div>
        Product Details
      </div>

      <div className="details-modal__body">
        <div className="details-modal__image">
          <Carousel images={(variant.images) || []} />
        </div>

        <div className="details-modal__text">
          <div className="details-modal__title">
            <div>{product.title}</div>
            {product.hasUniqueVariantTitle && (
              <div>{variant.variantTitle}</div>
            )}
          </div>
          <div
            className="details-modal__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DetailsModal;
