import React, { useState } from "react";
import _ from "lodash";
import { ShoppingCart } from "./store";
import classNames from "classnames";
import { createCheckout } from "../Replenish/api";
import queryString from "query-string";
import ModalCloseButton from "./ModalCloseButton";
import ShoppingCartIcon from "./ShoppingCartIcon";
import ShoppingCartLargeIcon from "./ShoppingCartLargeIcon";
import CartItem from "./CartItem";
import DownChevron from "./DownChevron";
import preventScroll from "./preventScroll";

const Cart = () => {
  const [processing, setProcessing] = useState(false);
  const [expanded, setExpanded] = useState(false);

  let count = 0;
  let total = 0;

  const cartItems = ShoppingCart.getCartItems();

  cartItems.forEach(cartItem => {
    count += cartItem.quantity;
    total += cartItem.quantity * cartItem.variant.price;
  })

  const checkout = async () => {
    if (!cartItems.length || processing) {
      return;
    }

    const checkoutItems = cartItems.map(cartItem => {
      return { id: cartItem.variant.id, quantity: cartItem.quantity };
    })

    const token = queryString.parse(location.search).t as string;

    try {
      setProcessing(true);
      window.location = await createCheckout(token, checkoutItems);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  }

  preventScroll(expanded);

  return (
    <div className={classNames("cart", {
      "cart--expanded": expanded,
    })}>
      <div className={classNames("cart__top", {
        "cart__top--expanded": expanded,
      })}>
        <div className="cart__title">
          <ModalCloseButton
            className="cart__close mobile-only"
            onClick={() => setExpanded(false)}
          />
          Your Cart

          {count > 0 && (
            <span className="badge badge--light mar-l-2">
              {count}
            </span>
          )}
        </div>
        <div className="cart__items">
          {count ? (
            cartItems.map(cartItem => (
              <CartItem cartItem={cartItem} key={cartItem.variant.id}/>
            ))
          ) : (
            <div className="cart__empty">
              <ShoppingCartLargeIcon />

              <div className="modal__title mar-t-4">
                Your Cart is Empty
              </div>

              <div className="modal__subtitle mar-t-2">
                Looks like you haven’t added anything yet. What are you running low on?
              </div>

              <button
                className="modal__secondary-button mobile-only mar-t-4"
                onClick={() => setExpanded(false)}
              >
                Continue Shopping
              </button>
            </div>
          )}

        </div>
      </div>

      <div className="cart__footer">
        <div
          className="cart__footer-left"
          onClick={() => setExpanded(!expanded)}
        >
          <ShoppingCartIcon />

          {count > 0 && (
            <span className="badge badge--small cart__footer-count">
              {count}
            </span>
          )}

          <div
            className={classNames("cart__expanded-caret", {
              "flip-y": expanded,
            })}
          >
            <DownChevron />
          </div>
        </div>

        <div className="cart__footer-right">
          {count > 0 && (
            <div className="cart__footer-text">
              <div className="cart__footer-price">
                ${total.toFixed(2)}
              </div>
              <div className="cart__footer-subtext">
                Plus tax & shipping
              </div>
            </div>
          )}
          <button
            className="cart__footer-button"
            disabled={count < 1 || processing}
            onClick={checkout}
          >
            Check Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;
