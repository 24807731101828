import React from "react";

const RightChevron = () => (
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.9425L3.7085 4.5L0 1.0575L1.1417 0L6 4.5L1.1417 9L0 7.9425Z"
      fill="#2D9AFF"
      className="brand-fill"
    />
  </svg>
);

export default RightChevron;
