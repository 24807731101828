import React from "react";
import Color from "color";
import { ApiShop } from "../Replenish/api";

const lightContrastColor = "#000000";

export default (shop?: ApiShop) => {
  if (!shop) {
    return null;
  }

  const { color, styles } = shop;
  const style = document.documentElement.style;

  if (color) {
    style.setProperty("--brand-color", color);
    style.setProperty("--brand-color-light", `${color}20`);

    if (Color(color).isLight()) {
      style.setProperty("--brand-contrast-color", lightContrastColor);
      style.setProperty("--brand-action-color", lightContrastColor);
      style.setProperty("--brand-color-light", color);
    } else {
      style.setProperty("--brand-action-color", color);
      style.setProperty("--brand-color-light", `${color}20`);
    }
  }

  if (styles) {
    if (styles.scripts) {
      document.head.innerHTML += styles.scripts;
    }

    if (styles.font) {
      style.setProperty("--font", styles.font);
    }

    if (styles.buttonPreference && styles.buttonPreference === 1) {

      style.setProperty("--button-radius", "0");
    }
  }
}
