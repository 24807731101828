import { Middleware, MiddlewareAPI } from "redux";
import queryString from "query-string";

let suppressionIndicatorShown = false;

const showSuppressionIndicator = () => {
  if (!suppressionIndicatorShown) {
    const indicator = document.createElement("div");
    indicator.className = "analytics-suppression-indicator"
    document.body.append(indicator)
    indicator.innerText = "analytics off";
    setTimeout(() => { indicator.remove() }, 3000);
    console.log("analytics suppressed")
    suppressionIndicatorShown = true;
  }
}

const suppressAnalytics = () => {
  const sa = queryString.parse(location.search).sa as string;

  if (sa) {
    const year = sa === "off" ? 2000 : 2030;
    const domain = location.hostname.split(".").slice(-2).join(".")
    document.cookie = `_sa=1; expires=Tue, 01 Jan ${year} 00:00:00 GMT; domain=.${domain}`;
  }

  if (document.cookie.includes("_sa=1")) {
    showSuppressionIndicator();
    return true;
  }
}

export const trackAnalytics = (str: String, store: MiddlewareAPI) => {
  const { userId, shop } = store.getState();

  const data = {
    user_id: userId,
    shop_id: shop && shop.id,
  };

  (window as any).analytics.track(str, data);
}

export const trackAnalyticsMiddleware: Middleware = store => next => action => {
  if (suppressAnalytics()) {
    return next(action);
  }

  if (action.type === "SET_PRODUCTS" && action.source == "variant") {
    trackAnalytics("variant updated", store)
  }

  if (action.type === "SET_CART") {
    trackAnalytics("quantity updated", store)
  }

  if (action.type === "SET_EXIT_MODAL" && action.show) {
    trackAnalytics("Logo clicked", store)
  }

  return next(action);
}
