import { createStore, applyMiddleware } from "redux";
import { ApiProduct, ApiProductGroup, ApiShop, ApiVariant } from "../Replenish/api";
import update from "immutability-helper";
import { useSelector } from "react-redux";
import _ from "lodash";
import { trackAnalyticsMiddleware } from "./trackAnalytics";

export interface State {
  cart: ApiCartItem[];
  detailsModal?: ApiProduct;
  moreOptionsModal?: ApiProduct;
  productGroups: ApiProductGroup[];
  shop?: ApiShop;
  showExitModal: boolean;
  userId?: string;
}

export interface ApiCartItem {
  product: ApiProduct,
  variant: ApiVariant,
  quantity: number,
}

const initialState = {
  showExitModal: false,
  selectedVariants: {},
  productGroups: [],
  cart: [],
  userId: null,
  shop: null,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return { ...state, productGroups: action.productGroups }
    case "SET_USER_ID":
      return { ...state, userId: action.userId }
    case "SET_SHOP":
      return { ...state, shop: action.shop }
    case "SET_EXIT_MODAL":
      return { ...state, showExitModal: action.show }
    case "SET_DETAILS":
      return { ...state, detailsModal: action.product }
    case "SET_MORE_OPTIONS":
      return { ...state, moreOptionsModal: action.product }
    case "SET_CART":
      return { ...state, cart: action.cart }
    default:
      return state;
  }
}

export const store = createStore(
  reducer,
  applyMiddleware(trackAnalyticsMiddleware)
);

export const setUserId = (userId?: string) => {
  store.dispatch({ type: "SET_USER_ID", userId });
}

export const setShop = (shop?: ApiShop) => {
  store.dispatch({ type: "SET_SHOP", shop });
}

export const setProductGroups = (productGroups?: ApiProductGroup[]) => {
  store.dispatch({ type: "SET_PRODUCTS", productGroups });
}

export const showDetails = (product?: ApiProduct) => {
  store.dispatch({ type: "SET_DETAILS", product });
};

export const showMoreOptions = (product?: ApiProduct) => {
  store.dispatch({ type: "SET_MORE_OPTIONS", product });
}

export const setQuantity = (product: ApiProduct, variant: ApiVariant, quantity: number) => {
  const cart: ApiCartItem[] = store.getState().cart;
  let newCart;
  let itemIndex;

  itemIndex = _.findIndex(cart, cartItem => cartItem.variant.id === variant.id)

  if (itemIndex === -1) {
    itemIndex = cart.length;
  }

  if (quantity === 0) {
    newCart = cart.filter((cartItem) => cartItem.variant.id !== variant.id);
  } else {
    newCart = update(cart, {
      [itemIndex]: { $set: { product, variant, quantity } },
    });
  }

  store.dispatch({ type: "SET_CART", cart: newCart });
};

export const setVariant = (product: ApiProduct, selectedVariant: number) => {
  product.selectedVariant = selectedVariant;
  const { productGroups } = store.getState();
  store.dispatch({ type: "SET_PRODUCTS", productGroups: [ ...productGroups ], source: "variant" });
};

export const ShoppingCart = class {
  static getCartItem = (variant: ApiVariant) => {
    const cart = useSelector((state: State) => state.cart);
    return cart.find((cartItem) => cartItem.variant.id === variant.id);
  }

  static getCartItems = () => {
    return useSelector((state: State) => state.cart);
  }

  static initialize = (productGroups: ApiProductGroup[]) => {
    if (productGroups[0]) {
      productGroups[0].products.forEach((product) => {
        const variant = product.variants[product.selectedVariant];
        if (variant.availableQuantity && !variant.removed) {
          setQuantity(product, variant, 1);
        }
      })
    }
  }
}