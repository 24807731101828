import React from "react";
import _ from "lodash";
import { setQuantity, ShoppingCart } from "./store";
import { ApiProduct, ApiVariant } from "../Replenish/api";

interface Props {
  product: ApiProduct;
  variant: ApiVariant;
  isCart?: Boolean;
}

const QuantityPicker = ({ product, variant, isCart }: Props) => {
  const { availableQuantity, removed } = variant;
  const cartItem = ShoppingCart.getCartItem(variant);
  const quantity = cartItem ? cartItem.quantity : 0;

  if (availableQuantity < 1) {
    return (
      <div className="unavailable">
        <div className="unavailable__label unavailable__label--red">
          Out of stock
        </div>
      </div>
    );
  }

  if (removed) {
    return (
      <div className="unavailable">
        <div className="unavailable__label">
          Unavailable
        </div>
      </div>
    );
  }

  return (
    <div className="mar-t-2">
      {quantity ? (
        <div className="flex space-between">
          <div>
            <select
              className="select select--quantity"
              onChange={({ target }) => setQuantity(product, variant, parseInt(target.value))}
              value={quantity}
            >
              <option value={0}>Remove</option>
              {_.range(1, availableQuantity + 1).map(count => (
                <option
                  value={count}
                  key={count}
                >
                  {count}
                </option>
              ))}
            </select>
            {!isCart && (
              <span className="mar-l-2">
                in cart
              </span>
            )}
          </div>
          {isCart && (
            <button
              className="button-as-link brand-action-color"
              onClick={() => setQuantity(product, variant, 0)}
            >
              Remove
            </button>
          )}
        </div>
      ) : (
        <button
          className="product__add"
          onClick={() => setQuantity(product, variant, 1)}
        >
          Add
        </button>
      )}
    </div>
  )
}

export default QuantityPicker;