import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-modal';
import ModalCloseButton from "./ModalCloseButton";
import preventScroll from "./preventScroll";

interface Props {
  url: string;
}

function ExitModal({ url }: Props) {
  const dispatch = useDispatch();

  const close = () => dispatch({ type: "SET_EXIT_MODAL", show: false });

  const showExitModal = useSelector(state => (state as any).showExitModal);

  preventScroll(showExitModal);

  return (
    <Modal
      isOpen={showExitModal}
      onRequestClose={close}
      className="exit-modal"
    >
      <div
        className="exit-modal__close-button"
        onClick={close}
      >
        <ModalCloseButton />
      </div>
      <div className="exit-modal__contents">
        <div className="exit-modal__title">
          Are you sure you want to leave?
        </div>
        <div className="exit-modal__subtitle">
          The items in your cart will not be saved.
          You can revisit this cart from the SMS link at anytime.
        </div>
        <div
          className="exit-modal__nevermind"
          onClick={close}
        >
          Nevermind
        </div>
        <a
          className="exit-modal__continue"
          href={url}
        >
          Continue to Website
        </a>
      </div>
    </Modal>
  );
}

export default ExitModal;
