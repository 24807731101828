import { useEffect } from "react";

export default (modalOpen: boolean) => {
  useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
  }, [modalOpen])
};