import React from "react";
import { useSelector } from "react-redux";
import { State, showMoreOptions } from "./store";
import preventScroll from "./preventScroll";
import Modal from 'react-modal';
import ModalCloseButton from "./ModalCloseButton";
import ProductGroup from "./ProductGroup";

const MoreOptionsModal = () => {
  const close = () => showMoreOptions(undefined)
  const product = useSelector((state: State) => state.moreOptionsModal);
  const isOpen = !!(product && product.moreOptions.length);

  preventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="details-modal"
      overlayClassName="ReactModal__Overlay--more-options"
    >
      <div className="details-modal__header">
        <div
          className="details-modal__close-button"
          onClick={close}
        >
          <ModalCloseButton />
        </div>
        More Options
      </div>

      <div className="details-modal__body details-modal__body--column">
        <ProductGroup
          name="Selected Product"
          products={product ? [{...product, moreOptions: []}] : []}
          type="more_options"
        />
        {product && (
          <ProductGroup
            name={
              product.moreOptionsType === "bundle"
              ? "Individual Products"
              : "More Options"
            }
            products={product.moreOptions}
            type="more"
          />
        )}
        <div className="details-modal__padding-bottom" />
      </div>
    </Modal>
  );
}

export default MoreOptionsModal;
