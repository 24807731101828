import React from "react";

const ShoppingCartLargeIcon = () => (
  <svg
    width="65"
    height="73"
    viewBox="0 0 65 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7.42126 3.75472C9.25879 4.54303 10.45 6.35025 10.45 8.34974V43C10.45 47.4183 14.0317
         51 18.45 51H56.35M11.35 14.5135H62.7071C63.3614 14.5135 63.8395 15.1316 63.675
         15.7649L58.3258 36.359C58.2202 36.7656 57.8719 37.0628 57.4538 37.103L11.35 41.5405"
      stroke="#A0B5C2"
      strokeWidth="0.5"
      strokeDasharray="3 3"
    />
    <circle cx="16.5" cy="65.5" r="7" stroke="#A0B5C2" strokeDasharray="3 3" />
    <circle cx="53.5" cy="65.5" r="7" stroke="#A0B5C2" strokeDasharray="3 3" />
  </svg>
);

export default ShoppingCartLargeIcon;