import React from "react";
import _ from "lodash";
import { ApiProduct } from "../Replenish/api";
import { showDetails, showMoreOptions } from "./store";
import QuantityPicker from "./QuantityPicker";
import VariantPicker from "./VariantPicker";
import RightChevron from "./RightChevron";
import ProductImage from "./ProductImage";

interface Props {
  product: ApiProduct;
}

function Product({ product }: Props) {
  const variant = product.variants[product.selectedVariant];

  return (
    <div className="card">
      <div className="product">
        <button
          className="button-as-link"
          onClick={() => showDetails(product)}
        >
          <ProductImage
            alt="product image"
            className="product__img"
            src={variant.images[0]}
          />
        </button>
        <div className="product__right">
          <div>
            <div className="product__header">
              <button
                className="product__title button-as-link"
                onClick={() => showDetails(product)}
              >
                {product.title}
              </button>
              <div className="product__price">
                {variant.price && `$${variant.price}`}
              </div>
            </div>

            <VariantPicker product={product} />

            {product.variants.length === 1 && product.hasUniqueVariantTitle && (
              <div className="product__subtitle mar-t-1">
                {variant.variantTitle}
              </div>
            )}
          </div>

          <QuantityPicker product={product} variant={variant} />
        </div>
      </div>

      {product.moreOptions && product.moreOptions.length > 0 && (
        <>
          <div className="card__divider" />

          <div
            className="more-options pointer"
            onClick={() => showMoreOptions(product)}
          >
            <div className="more-options__title">
              More Options
              <div className="more-options__count">
                {product.moreOptions.length}
              </div>
            </div>

            <RightChevron />
          </div>
        </>
      )}
    </div>
  );
}

export default Product;
