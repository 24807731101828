import React from "react";
import Styles from "./ErrorPage.module.css";
import { useShop } from "./shopService";

const buttonClicked = (shop: any, link: string) => {

  (window as any).analytics.track('Logo clicked Error Page', {
    context: {shop_id: shop.id, shop_name: shop.name}
  });
  if(link.indexOf('http')>0){
    window.location.href = link+'?utm_source=repeat&utm_medium=replen_cart_error_page';
  }else{
    window.location.href = 'http://'+link+'?utm_source=repeat&utm_medium=replen_cart_error_page';
  }

};

export const ErrorPage = ({ withText }: { withText?: string }, shop: any) => {
  const {
    name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    error
  } = useShop('0');
  const shopLightColor = lightColor || '#fafafa';
  const shopColor = color || '#001343';
  document.body.style.backgroundColor = shopLightColor;
    return (
      <div className={Styles.ErrorPage} style={{ backgroundColor: shopLightColor }}>
        <div className={Styles.Box}>
          <div className={Styles.Logo} onClick={() => buttonClicked(shop, storeUrl)}>
            <img src={logoUrl}/>
          </div>
          <p className={Styles.Oops} style={{color: shopColor}}>Oops...</p>
          <p className={Styles.ErrorText}>{withText}</p>
          <button onClick={() => buttonClicked(shop, storeUrl)} style={{backgroundColor: shopColor, color: shopLightColor}}>
            <span className={Styles.CheckoutTitle}>BACK TO STORE</span>
          </button>
        </div>
      </div>
    );
}
