import { useState, useEffect } from "react";
import camelize from "camelize";

const VELOCITY_URL = process.env.REACT_APP_VELOCITY_URL;

const u = (url: string) => {
  if (VELOCITY_URL) {
    return VELOCITY_URL + url;
  } else {
    return url;
  }
};

interface ApiShop {
  askEmail: boolean;
  color: string;
  domain: string;
  id: string;
  lightColor: string;
  logoUrl: string;
  name: string;
  optinSettings: {
    discountText: string;
    discountUrl: string;
    modal: {
      body: string;
      title: string;
    };
    page: {
      body: string;
      title: string;
    };
    termsOfService: string;
  };
  policyUrl: string;
  previewUrl: string;
  storeUrl: string;
  tosUrl: string;
  styles: {
    font: string;
    buttonPreference: number;
    scripts: string;
  };
}

export const getShop = async (
  shopId: string
): Promise<ApiShop> => {

  const response = await fetch(u(`/api/shops/${shopId}`), {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  
  if (response.status !== 200) {
    throw new Error(`Unexpected status received: ${response.status}`);
  }
  const json = await response.json();
  const camelizedResponse = camelize(json);

  return {
    name: camelizedResponse.shop.data.attributes.name,
    logoUrl: camelizedResponse.shop.data.attributes.logoUrl,
    storeUrl: camelizedResponse.shop.data.attributes.publicDomain,
    color: camelizedResponse.shop.data.attributes.color,
    lightColor: camelizedResponse.shop.data.attributes.lightColor,
    domain: camelizedResponse.shop.data.attributes.domain,
    id: camelizedResponse.shop.data.attributes.id,
    tosUrl: camelizedResponse.shop.data.attributes.tosUrl,
    policyUrl: camelizedResponse.shop.data.attributes.policyUrl,
    previewUrl: camelizedResponse.shop.data.attributes.previewUrl,
    optinSettings: camelizedResponse.shop.data.attributes.optinSettings,
    askEmail: camelizedResponse.shop.data.attributes.askForEmail,
    styles: camelizedResponse.shop.data.attributes.styles,
  };
};


export const useShop = (shopId: string) => {

  const [name, setName] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [storeUrl, setStoreUrl] = useState();
  const [color, setColor] = useState();
  const [lightColor, setLightColor] = useState();
  const [error, setError] = useState();
  const [domain, setDomain] = useState();
  const [tosUrl, setTosUrl] = useState();
  const [optinSettings, setOptinSettings] = useState();
  const [policyUrl, setPolicyUrl] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [askEmail, setAskEmail] = useState();
  const [styles, setStyles] = useState();

  useEffect(() => {
    const fetchShop = async () => {
      try {
        const {
            name,
            logoUrl,
            storeUrl,
            color,
            lightColor,
            domain,
            id,
            tosUrl,
            policyUrl,
            previewUrl,
            optinSettings,
            askEmail,
            styles,
        } = await getShop(shopId);
        setName(name);
        setLogoUrl(logoUrl);
        setStoreUrl(storeUrl);
        setColor(color);
        setLightColor(lightColor);
        setDomain(domain);
        setTosUrl(tosUrl);
        setPolicyUrl(policyUrl);
        setPreviewUrl(previewUrl);
        setOptinSettings(optinSettings);
        setAskEmail(askEmail);
        setStyles(styles);
      } catch (e) {
        console.error(e);
        setError(":( Something went wrong.");
      }
    };
    fetchShop();
  }, [shopId]);
  return { name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    domain,
    tosUrl,
    policyUrl,
    previewUrl,
    optinSettings,
    askEmail,
    error,
    styles,
  };
};
