import React, { useState } from "react";
import { ApiProductGroup } from "../Replenish/api";
import Product from "./Product";
import RecommendedProduct from "./RecommendedProduct";
import { trackAnalytics } from "./trackAnalytics";
import { useSelector, useStore } from "react-redux";
import { State } from "./store";

interface Props extends ApiProductGroup {
  type: string;
  expandable?: boolean;
}

const ProductGroup = ({ name, products, type, expandable }: Props)=> {
  const [expanded, setExpanded] = useState(false);
  const store = useStore();

  const toggleExpanded = () => {
    if (!expanded) {
      trackAnalytics("Show More clicked", store);
    }
    setExpanded(!expanded);
  }

  if (products.length < 1) {
    return null;
  }

  let visibleProducts = products;

  if (expandable && !expanded) {
    visibleProducts = products.slice(0, 3);
  }

  return (
    <div className="product-group">
      <h3 className="h3">{name}</h3>

      <div className={`product-group__items product-group__items--${type}`}>
        {visibleProducts.map(product => (
          type === "recommended" ? (
            <RecommendedProduct product={product} key={product.id} />
          ) : (
            <Product product={product} key={product.id} />
          )
        ))}
      </div>

      {expandable && products.length > 3 && (
        <div className="product-group__expander">
          <hr className="product-group__expander-line" />
          <div
            className="product-group__expander-text button-as-link"
            onClick={toggleExpanded}
          >
            Show {expanded ? "Less" : "More"}
          </div>
          <hr className="product-group__expander-line" />
        </div>
      )}
    </div>
  );
}

export default ProductGroup;