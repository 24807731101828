import React from "react";
import _ from "lodash";
import QuantityPicker from "./QuantityPicker";
import { ApiCartItem, showDetails } from "./store";

interface Props {
  cartItem: ApiCartItem;
}

function CartItem({ cartItem }: Props) {
  const { product, variant } = cartItem;

  const extendedCost = (variant.price * cartItem.quantity).toFixed(2);

  return (
    <div className="product product--cart">
      <button
        className="button-as-link"
        onClick={() => showDetails(product)}
      >
        <img
          className="product__img product__img--cart"
          src={variant.imgUrl} alt="product image"
        />
      </button>
      <div className="product__right">
        <div>
          <div className="product__header">
            <div
              className="product__title product__title--cart button-as-link"
              onClick={() => showDetails(product)}
            >
              {product.title}
            </div>

            <div className="product__price product__price--cart">
              ${extendedCost}
            </div>
          </div>
          {(product.variants.length > 1 || product.hasUniqueVariantTitle) && (
            <div className="product__subtitle mar-t-1">
              {variant.variantTitle}
            </div>
          )}
        </div>

        <QuantityPicker
          product={product}
          variant={variant}
          isCart
        />
      </div>
    </div>
  );
}

export default CartItem;
