import React from "react";
import _ from "lodash";
import { ApiProduct } from "../Replenish/api";
import { showDetails } from "./store";
import QuantityPicker from "./QuantityPicker";
import VariantPicker from "./VariantPicker";

interface Props {
  product: ApiProduct;
}

function RecommendedProduct({ product }: Props) {
  const variant = product.variants[product.selectedVariant];
  const variantPickerHidden = product.variants.length === 1;

  return (
    <div className="card">
      <div className="product">
        <button
          className="button-as-link product__img-container"
          onClick={() => showDetails(product)}
        >
          <img
            className="product__img product__img--recommended"
            src={variant.imgUrl} alt="product image"
          />
        </button>
        <div className="product__right">
          <div>
            <div className="product__header">
              <div
                className="product__title product__title--recommended button-as-link"
                onClick={() => showDetails(product)}
              >
                {product.title}
              </div>

            </div>

            {product.hasUniqueVariantTitle && variantPickerHidden && (
              <div className="product__subtitle mar-t-1">
                {variant.variantTitle}
              </div>
            )}

            <div className="product__price product__price--recommended">
              ${variant.price}
            </div>

            <VariantPicker product={product} />
          </div>
          <QuantityPicker product={product} variant={variant} />
        </div>
      </div>
    </div>
  );
}

export default RecommendedProduct;
