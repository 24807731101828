import React, { useState } from "react";
import ProductImage from "./ProductImage";

interface Props {
  images: string[];
}

function Carousel({ images }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="carousel">
      <ProductImage
        src={images[currentIndex]}
        className="carousel__image"
      />
      {images.length > 1 && (
        <>
          <div className="carousel__buttons">
            <button
              className="carousel__button carousel__button--left button-as-link"
              onClick={() => setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1)}
            >
              <svg width="12" height="16" viewBox="0 0 12 16">
                <path
                  d="M11.25 14.1494L4.76012 8.125L11.25 2.10062L9.25202
                    0.249999L0.75 8.125L9.25202 16L11.25 14.1494Z"
                  className="brand-fill"
                />
              </svg>
            </button>
            <button
              className="carousel__button carousel__button--right button-as-link"
              onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}
            >
              <svg width="12" height="16" viewBox="0 0 12 16">
                <path
                  d="M0.75 14.1494L7.23988 8.125L0.75 2.10062L2.74798
                    0.249999L11.25 8.125L2.74798 16L0.75 14.1494Z"
                  className="brand-fill"
                />
              </svg>
            </button>
          </div>
          <div className="carousel__dots">
            {images.map((image, index) => (
              <div className="carousel__dot" key={index}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="4.5" className="brand-stroke" />
                  {index === currentIndex && (
                    <circle cx="5" cy="5" r="1.5" className="brand-fill brand-stroke" />
                  )}
                </svg>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Carousel;
