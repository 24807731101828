import React from "react";

const DownChevron = () => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.840778 0L3.57778 2.47233L6.31478 0L7.15556 0.761133L3.57778 4L0 0.761133L0.840778 0Z"
      fill="#2D9AFF"
      className="brand-fill"
    />
  </svg>
);

export default DownChevron;
