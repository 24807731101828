export default class optinApi {
  domain: string;

  constructor(domain: string) {
    this.domain = domain;
  }

  sendVerifyCode = (phoneNumber: string, email: string) => {
    return fetch(this.getUrl("/api/public/users.sendVerifyCode"), {
    method: "post",
    body: JSON.stringify({
      email: email,
      phone_number: phoneNumber,
      domain: this.domain
    }),
      headers: { "Content-Type": "application/json" }
    });
  };

  submitVerifyCode = (code: string, phoneNumber: string, email: string, acceptMarketing: boolean) => {
    return fetch(this.getUrl("/api/public/users.createFromCheckout"), {
    method: "post",
    body: JSON.stringify({
      email: email,
      code,
      phone_number: phoneNumber,
      domain: this.domain,
      accept_marketing: acceptMarketing
    }),
      headers: { "Content-Type": "application/json" }
    });
  };

  getUrl(url: string) {
    return process.env["REACT_APP_VELOCITY_URL"] + url;
  }

  parseJSON(response: Response) {
      return response.json();
  }
}