import React from "react";

const ShoppingCartIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66418 26.3625C7.66418 27.8513 8.87543 29.0625 10.3642 29.0625C11.8529 29.0625
         13.0641 27.8514 13.0641 26.3625C13.0641 24.8737 11.8529 23.6626 10.3642 23.6626C8.87543
         23.6626 7.66418 24.8738 7.66418 26.3625ZM11.8141 26.3625C11.8141 27.162 11.1637 27.8125
         10.3642 27.8125C9.56462 27.8125 8.91418 27.1621 8.91418 26.3625C8.91418 25.563 9.56462
         24.9126 10.3642 24.9126C11.1637 24.9126 11.8141 25.563 11.8141 26.3625Z"
      fill="black"
    />
    <path
      d="M23.5032 23.6626C22.0144 23.6626 20.8032 24.8738 20.8032 26.3625C20.8032 27.8513 22.0144
         29.0625 23.5032 29.0625C24.9919 29.0625 26.2031 27.8514 26.2031 26.3625C26.2031 24.8737
         24.9919 23.6626 23.5032 23.6626ZM23.5032 27.8125C22.7037 27.8125 22.0532 27.1621 22.0532
         26.3625C22.0532 25.563 22.7037 24.9126 23.5032 24.9126C24.3027 24.9126 24.9531 25.563
         24.9531 26.3625C24.9531 27.1621 24.3027 27.8125 23.5032 27.8125Z"
      fill="black"
    />
    <path
      d="M7.18426 3.77242L5.04638 2.97686C4.72313 2.85661 4.36301 3.02111 4.24269 3.34461C4.12238
         3.66811 4.28694 4.02799 4.61051 4.14836L6.74838 4.94392C7.23526 5.12511 7.56238 5.59592
         7.56238 6.11549V18.9124C7.56238 20.6355 8.96426 22.0374 10.6874 22.0374H24.3281C24.6733
         22.0374 24.9531 21.7575 24.9531 21.4124C24.9531 21.0672 24.6733 20.7874 24.3281
         20.7874H10.6874C9.65351 20.7874 8.81238 19.9462 8.81238 18.9124V18.5174L23.5621
         17.2402C24.9046 17.124 26.0176 16.1654 26.3315 14.8549L27.7791 8.81174C27.8233 8.6273
         27.7814 8.4328 27.6651 8.28292C27.5488 8.13311 27.3708 8.04417 27.1811 8.04117L8.81238
         7.75436V6.11549C8.81238 5.07642 8.15807 4.13486 7.18426 3.77242ZM26.3818 9.27886L25.1159
         14.5637C24.9275 15.35 24.2598 15.9251 23.4543 15.9949L8.81238 17.2627V9.00455L26.3818
         9.27886Z"
      fill="black"
    />
  </svg>
);

export default ShoppingCartIcon;