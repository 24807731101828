import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiVariant, ApiProduct } from "../Replenish/api";
import _ from "lodash";
import { setVariant } from "./store";
import AutoResizeSelect from "./AutoResizeSelect";

interface Props {
  product: ApiProduct;
}

const VariantPicker = ({ product }: Props) => {
  if (product.variants.length < 2) {
    return null;
  }

  return (
    <div className="variant-picker">
      <select
        className="select select--max-width mar-t-1"
        onChange={(event) => setVariant(product, parseInt(event.target.value))}
        value={product.selectedVariant}
      >
        {product.variants.map((variant, index) => (
          <option
            value={index}
            key={variant.variantTitle}
          >
            {variant.variantTitle}
          </option>
        ))}
      </select>
    </div>
  );
}

export default VariantPicker;