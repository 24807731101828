import React, { useEffect } from "react";
import { useDispatch, useSelector, Provider } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import { ErrorPage } from "../ErrorPage";
import { useReplenishCart } from "../Replenish/api";
import { useShop } from "../shopService";
import { LoadingTrianglePage } from "../components/LoadingTriangle";
import { store, State, ShoppingCart, setProductGroups, setShop, setUserId } from "./store";
import setBrandStyles from "./setBrandStyles";
import DetailsModal from "./DetailsModal";
import ExitModal from "./ExitModal";
import MoreOptionsModal from "./MoreOptionsModal";
import ProductGroup from "./ProductGroup";
import Cart from "./Cart";
import MetaTags from "react-meta-tags";

function ShopperPage() {
  const token = queryString.parse(location.search).t as string;
  const shopId = process.env.REACT_APP_SHOP_ID || '0';

  if (!token) {
    return <ErrorPage withText={'Url is invalid. Maybe try clicking it again?'} />;
  }

  let {
    error,
    productGroups,
    userId,
    shop
  } = useReplenishCart(token);

  const {
    name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    domain,
    tosUrl,
    policyUrl,
    previewUrl,
    optinSettings,
    askEmail,
    styles,
  } = useShop(shopId);

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    setProductGroups(productGroups);
    ShoppingCart.initialize(productGroups);
  }, [productGroups]);

  useEffect(() => {
    setBrandStyles(shop);
    setShop(shop);
  }, [shop]);

  const dispatch = useDispatch()

  const showExitModal = () =>
    dispatch({ type: "SET_EXIT_MODAL", show: true });

  const stateProductGroups = useSelector((state: State) => state.productGroups);

  if (error) {
    return <ErrorPage withText={error} />;
  }

  if (!shop) {
    return <LoadingTrianglePage withText={"Preparing cart..."} />;
  }

  return (
    <>
      <ExitModal url={shop.storeUrl} />
      <MoreOptionsModal />
      <DetailsModal />
      <MetaTags>
        <title>{name}</title>
        <meta property="og:title" content={`Click here to reorder your ${name} products`}/>
        <meta property="og:image" content={previewUrl} />
      </MetaTags>

      <div className="replenish-header">
        <div className="replenish-container">
          <button
            className="button-as-link"
            onClick={showExitModal}
          >
            <img
              src={shop.logoUrl}
              alt="logo"
              className="brand-logo"
            />
          </button>
        </div>
      </div>

      <div className="replenish-body">
        <div className="replenish-container">
          <div className="product-groups">
            <ProductGroup {...stateProductGroups[0]} type="due" />
            <ProductGroup {...stateProductGroups[1]} type="previous" expandable />
            <ProductGroup {...stateProductGroups[2]} type="recommended" />
          </div>

          <Cart />
        </div>
      </div>
    </>
  );
}

const ShopperPageWithStore = () => (
  <Provider store={store}>
    <ShopperPage />
  </Provider>
)

export default ShopperPageWithStore;
