import React, {useEffect, useState, CSSProperties} from "react";
import Styles from "./OptinPage.module.css";
import { useShop } from "../shopService";
import optinApi from './optinApi';
import InputMask from "react-input-mask";
import ReactMarkdown from "react-markdown";
import queryString from 'query-string';
import setBrandStyles from "../Replenish/setBrandStyles";
import MetaTags from "react-meta-tags";

const logoClicked = (shop: any, link: string) => {
  (window as any).analytics.track('go_to_brand_page_from_opt_in_page', {
    context: {shop_id: shop.id, shop_name: shop.name}
  });
  if(link.indexOf('http')>0){
    window.location.href = link+'?utm_source=repeat&utm_medium=opt-in-page-home';
  }else{
    window.location.href = 'http://'+link+'?utm_source=repeat&utm_medium=opt-in-page-home';
  }
};

const OptinPage = ({ withText }: { withText?: string }, shop: any) => {
  const {
    name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    domain,
    tosUrl,
    policyUrl,
    previewUrl,
    optinSettings,
    askEmail,
    error,
    styles,
  } = useShop('0');

  const queryStringEmail = String(queryString.parse(window.location.search).email || "");
  const REQUEST_DATA = 'requestData';
  const VERIFY_CODE = 'verifyCode';
  const CONFIRMATION_MESSAGE = 'confirmationMessage';
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(queryStringEmail);
  const [confirmation, setConfirmation] = useState("");
  const [tos, setTos] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(true);
  const [step, setStep] = useState(REQUEST_DATA);
  const [errorMessage, setErrorMessage] = useState("");
  const shopLightColor = lightColor || '#fafafa';
  const shopColor = color || '#001343';

  const handleTos = (event: React.MouseEvent<HTMLInputElement>) => {
    setTos(!tos);
  };

  const handleTosInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTos(event.target.checked);
  };

  const handleAcceptMarketing = (event: React.MouseEvent<HTMLInputElement>) => {
    setAcceptMarketing(!acceptMarketing);
  };

  const handleAcceptMarketingInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptMarketing(event.target.checked);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  };

  const handleConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(event.target.value)
  };

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
  };

  const requestVerificationNumber = (shop: any, link: string) => {
    setErrorMessage("");
    const api = new optinApi(domain);
    if(tos && phoneNumber && email){
      api.sendVerifyCode(phoneNumber, email)
        .then(async r => {
          if(r.status === 200){
            setStep(VERIFY_CODE);
            setErrorMessage("");
          }else{
            const response = await r.json();
            setErrorMessage(response.error.detail);
          }
        })
        .catch(() => {
          setErrorMessage("Uh oh. Something went wrong. Try one more time?");
          setStep(REQUEST_DATA);
        });
    }else{
      if(!tos){
        setErrorMessage("Please accept the terms of service to move forward.");
      }
      if(!phoneNumber){
        setErrorMessage("Please enter your phone number.");
      }
      if(!email){
        setErrorMessage("Please enter your email address.");
      }
      setStep(REQUEST_DATA);
    }
  };

  const clickConfirmationLink = (shop: any, link: string) => {
    setErrorMessage("");
    if(link.indexOf('http') === 0){
      window.location.href = link;
    }else{
      window.location.href = 'http://'+link;
    }
  };

  const sendVerificationNumber = (shop: any, link: string) => {
    setErrorMessage("");
    const api = new optinApi(domain);
    if(confirmation){
      api.submitVerifyCode(confirmation, phoneNumber, email, (askEmail && acceptMarketing))
        .then(async r => {
          if(r.status === 200){
            setStep(CONFIRMATION_MESSAGE);
          }else{
            const response = await r.json();
            setErrorMessage(response.error.detail);
          }
        }).catch(() => {
          setErrorMessage("Uh oh. Something went wrong. Try one more time?");
          setStep(REQUEST_DATA);
        });
    }else{
      if(!confirmation){
        setErrorMessage("Please inform the confirmation code sent by SMS.");
      }
    }
  };

  useEffect(() => {
    setBrandStyles({ color, styles } as any);
  }, [color, styles]);

  document.body.style.backgroundColor = shopLightColor;
    return (
      <>
        <MetaTags>
          <title>{name}</title>
          <meta property="og:title" content={`Click here to reorder your ${name} products`}/>
          <meta property="og:image" content={previewUrl} />
        </MetaTags>
        <div className={Styles.OptinPage} style={{ backgroundColor: shopLightColor }}>
          <div className={Styles.Box}>
            <div className={Styles.Logo} onClick={() => logoClicked(shop, storeUrl)}>
              <img src={logoUrl}/>
            </div>
            <div
              className="header-description-container"
              style={step === CONFIRMATION_MESSAGE ? {'display': 'none'} : {}}
            >
              <h1>
                {step === REQUEST_DATA && (
                  <>
                    <div className={Styles.PhoneIcon} />
                    {optinSettings && optinSettings.page.title}
                  </>
                )}
                {step === VERIFY_CODE && (
                  <div className="center">
                    Almost done!
                  </div>
                )}
              </h1>
            </div>
            <div className={Styles.Header} style={step === CONFIRMATION_MESSAGE ? {'display': 'none'} : {}}>
              <div className="header-description">
                {step === REQUEST_DATA && (
                  <h3 dangerouslySetInnerHTML={{ __html: optinSettings && optinSettings.page.body }}/>
                )}
                {step === VERIFY_CODE && (
                  <h3 className="center">
                    A verification code was sent to your phone number.
                    Please input it here to confirm sign up.
                  </h3>
                )}
              </div>
            </div>
            <div className={Styles.Error} style={error !== '' ? {} : {'display': 'none'}}>
              {errorMessage}
            </div>
            <div className={Styles.InputArea} style={step === REQUEST_DATA ? {} : {'display': 'none'}}>
              <input
                className={Styles.TextField}
                placeholder="Enter email address"
                value={email}
                name="email"
                type="mail"
                onChange={handleEmail}
                autoFocus={true}
              />
              <InputMask
                maskChar="_"
                mask="+1 (999) 999-9999"
                className={Styles.TextField}
                placeholder="Enter phone number"
                onChange={handlePhoneNumber}
              />
              <div className={Styles.TosArea} style={askEmail ? ({'--color-primary': shopColor} as CSSProperties) : {'display': 'none'}}>
                <span onClick={handleAcceptMarketing}>
                  <input type="checkbox" checked={acceptMarketing} name="tos" onChange={handleAcceptMarketingInput}/>
                  <span/>
                </span>
                <span>
                  I agree to receive {name}'s email marketing
                </span>
              </div>
              <div className={Styles.TosArea} style={({'--color-primary': shopColor} as CSSProperties)}>
                <span onClick={handleTos}>
                  <input type="checkbox" checked={tos} name="tos" onChange={handleTosInput}/>
                  <span/>
                </span>
                {(optinSettings && optinSettings.termsOfService) ? (
                  <ReactMarkdown
                    source={optinSettings && optinSettings.termsOfService}
                    linkTarget={"_"}
                    renderers={{ paragraph: 'span' }}
                  />
                ) : (
                  <span>
                    By submitting, I agree to {name}'s&nbsp;
                    <a href={tosUrl} target="_blank">
                      Terms of Service
                    </a>
                      &nbsp;and&nbsp;
                    <a href={policyUrl} target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </span>
                )}
              </div>
              <p className={Styles.Text}>{withText}</p>
              <button onClick={() => requestVerificationNumber(shop, storeUrl)} className="brand-button">
                <span className={Styles.CheckoutTitle}>Next Step</span>
              </button>
            </div>
            <div className={Styles.Error} style={error !== '' ? {} : {'display': 'none'}}>
              {errorMessage}
            </div>

            <div className={Styles.InputArea} style={step === VERIFY_CODE ? {} : {'display': 'none'}}>
              <input
                className={Styles.TextField}
                placeholder="Enter the verification code"
                value={confirmation}
                name="confirmation"
                type="text"
                onChange={handleConfirmation}
                autoFocus={true}
              />
              <p className={Styles.Text}>{withText}</p>
              <button onClick={() => sendVerificationNumber(shop, storeUrl)} className="brand-button">
                <span className={Styles.CheckoutTitle}>Submit</span>
              </button>
            </div>
            <div className={Styles.BackLink} style={step === CONFIRMATION_MESSAGE ? {'display': 'none'} : {}}>
              <a onClick={() => logoClicked(shop, storeUrl)} className="brand-action-color">
                Return to {name}
              </a>
            </div>
            <div className={Styles.ConfirmationArea} style={step === CONFIRMATION_MESSAGE ? {} : {'display': 'none'}}>
              <p className={Styles.Text}>Thank you!</p>
              <span><b>{optinSettings && optinSettings.discountText}</b></span>
              <button onClick={() => clickConfirmationLink(shop, optinSettings.discountUrl)} className={`${Styles.ShopNow} brand-button`}>
                <span className={Styles.CheckoutTitle}>SHOP NOW</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

export default OptinPage;
