import React from "react";

const ModalCloseButton = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={ {cursor: "pointer" }}
    {...props}
  >
    <path
      d="M18.4736 1.52638C18.1365 1.18934 17.6793 1 17.2026 1C16.7258 1 16.2686 1.18934 15.9315
        1.52638L10.0112 7.44663L4.09099 1.52638C3.75191 1.19889 3.29778 1.01768 2.82639
        1.02178C2.35501 1.02587 1.90409 1.21495 1.57076 1.54828C1.23742 1.88161 1.04835 2.33253
        1.04425 2.80392C1.04016 3.2753 1.22137 3.72944 1.54886 4.06851L7.46911 9.98876L1.54886
        15.909C1.37714 16.0749 1.24018 16.2732 1.14596 16.4926C1.05174 16.7119 1.00214 16.9478
        1.00007 17.1865C0.997994 17.4253 1.04348 17.662 1.13388 17.8829C1.22427 18.1039 1.35777
        18.3046 1.52657 18.4734C1.69537 18.6422 1.89611 18.7757 2.11705 18.8661C2.338 18.9565
        2.57474 19.002 2.81345 18.9999C3.05217 18.9979 3.28808 18.9483 3.50742 18.854C3.72676
        18.7598 3.92514 18.6229 4.09099 18.4511L10.0112 12.5309L15.9315 18.4511C16.2706 18.7786
        16.7247 18.9598 17.1961 18.9557C17.6675 18.9516 18.1184 18.7626 18.4517 18.4292C18.7851
        18.0959 18.9741 17.645 18.9782 17.1736C18.9823 16.7022 18.8011 16.2481 18.4736
        15.909L12.5534 9.98876L18.4736 4.06851C18.8107 3.73137 19 3.27416 19 2.79745C19 2.32073
        18.8107 1.86352 18.4736 1.52638Z"
      fill="#2D9AFF"
      className="brand-fill"
      stroke="white"
    />
  </svg>
);

export default ModalCloseButton;