import React from "react";
import ReactDOM from "react-dom";
import * as FullStory from "@fullstory/browser";
import { ErrorPage } from "./ErrorPage";

import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "./index.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ShopperPage from "./Replenish/ShopperPage";
import OptinPage from "./Optin/OptinPage";

const isDevelopment = process.env.NODE_ENV === "development";

FullStory.init({ orgId: "CSEZ3", devMode: isDevelopment });

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/replenish" component={ShopperPage} />
      <Route path="/optin" component={OptinPage} />
      <Route
        exact
        path="/"
        render={() => (window.location.href = "https://getrepeat.io")}
      />
      <ErrorPage withText={"Sorry this page was not found."} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
