import { useState, useEffect } from "react";
import camelize from "camelize";

const VELOCITY_URL = process.env.REACT_APP_VELOCITY_URL;

const u = (url: string) => {
  if (VELOCITY_URL) {
    return VELOCITY_URL + url;
  } else {
    return url;
  }
};

export interface CheckoutItem {
  id: string;
  quantity: number;
}

export const createCheckout = async (token: string, items: CheckoutItem[]) => {
  const response = await fetch(u("/api/checkouts"), {
    method: "post",
    body: JSON.stringify({
      t: token,
      items
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  if (response.status !== 201) {
    throw new Error(`Unexpected status received: ${response.status}`);
  }
  const json = await response.json();
  return json["checkout_url"];
};

export interface ApiProductGroup {
  name: string;
  products: ApiProduct[];
}

interface ApiUser {
  id: string;
  firstName: string;
}

export interface ApiVariant{
  id: string;
  variantTitle: string;
  description: string;
  price: number;
  lastPurchased: string;
  imgUrl: string;
  images: string[];
  preselected: boolean;
  selected: boolean;
  quantity: number;
  removed: boolean;
  availableQuantity: number;
}

export interface ApiProduct {
  hasUniqueVariantTitle: boolean;
  id: string;
  title: string;
  lastPurchased: string;
  selectedVariant: number;
  variants: ApiVariant[];
  moreOptions: ApiProduct[];
  moreOptionsType: string;
}

export interface ApiShop {
  color: string;
  id: string;
  lightColor: string;
  logoUrl: string;
  name: string;
  optinSettings: {
    discountText: string;
    discountUrl: string;
    modal: {
      body: string;
      title: string;
    };
    page: {
      body: string;
      title: string;
    };
    termsOfService: string;
  };
  previewUrl: string;
  storeUrl: string;
  styles: {
    font: string;
    buttonPreference: number;
    scripts: string;
  };
}

interface GetReplenishCartResponse {
  products: ApiProduct[];
  productGroups: ApiProductGroup[];
  user: ApiUser;
  shop: ApiShop;
}

export const getReplenishCart = async (
  token: string,
): Promise<GetReplenishCartResponse> => {
  const response = await fetch(u(`/api/users.replenishCart?t=${token}`), {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
  if (response.status !== 200) {
    throw new Error(`Unexpected status received: ${response.status}`);
  }
  const json = await response.json();
  const j = camelize(json);

  return {
    user: stringifyId(j.user),
    productGroups: j.productGroups,
    products: j.productGroups.flatMap((group: any) => group.products).map((p: any) => stringifyId(p)),
    shop: j.shop
  };
};

const stringifyId = (j: any) => ({
  ...j,
  id: `${j.id}`
});

type ProductsState = ApiProduct[];
type ProductGroupsState = ApiProductGroup[];

const initialProductState: ProductsState = [];
const initialProductGroupState: ProductGroupsState = [];

export const useReplenishCart = (token: string) => {
  const [products, setProducts] = useState(initialProductState);
  const [productGroups, setProductGroups] = useState(initialProductGroupState)
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState();
  const [userId, setUserId] = useState();
  const [shop, setShop] = useState<ApiShop>();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const {
          products,
          productGroups,
          user,
          shop
        } = await getReplenishCart(token);
        setProducts(products);
        setProductGroups(productGroups);
        setShop(shop);
        setFirstName(user.firstName);
        setUserId(user.id);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(":( Something went wrong.");
        setLoading(false);
      }
    };
    fetchProducts();
  }, [token]);

  return { products, productGroups, shop, loading, userId, firstName, error };
};
