import React from "react";

const defaultImage = "/unavailable-image.png";

export default (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { src, ...allProps } = props;

  return (
    <img
      {...allProps}
      src={src || defaultImage}
    />
  );
}
