import * as React from "react";
import { useShop } from "../shopService";
import "./LoadingTriangle.css";

export const LoadingTriangle = () => {
  const {
    name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    error
  } = useShop('0');
  const background = {'--color': color} as React.CSSProperties;
  return (
  <div className="lds-roller" style={background}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)};

export const LoadingTrianglePage = ({ withText }: { withText?: string }) => {
  const {
    name,
    logoUrl,
    storeUrl,
    color,
    lightColor,
    error
  } = useShop('0');
  const background = {'--color': color} as React.CSSProperties;

  return (
  <div className="LoadingTriangleContainer">
    <div className="lds-roller" style={background}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    {withText && <h3>{withText}</h3>}
  </div>
)};
